<template>
    <ion-page>
        <HeaderOne v-bind:name="$t('news')" />
        <ion-content :fullscreen="true">
            <ion-header collapse="condense">
                <ion-toolbar>
                    <ion-title size="large">
                        {{$t('news')}}
                    </ion-title>
                </ion-toolbar>
            </ion-header>

            <div class="credits">
                <h2>{{ $t('credits') }}</h2>
                <div v-html="$t('credits_text')"></div>
            </div>

        </ion-content>
    </ion-page>
</template>

<script lang="ts">
    import { IonPage, IonHeader, IonToolbar, IonTitle, IonSearchbar, IonSelect, IonSelectOption, IonItem, IonContent, IonLabel, IonSpinner, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonBadge, } from '@ionic/vue';
    import HeaderOne from '@/components/HeaderOne.vue';

    export default {
        name: 'Credits',
        components: { IonHeader, IonToolbar, IonTitle, IonPage, HeaderOne, IonSearchbar, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonBadge, IonSelect, IonSelectOption, IonItem, IonContent, IonLabel, IonSpinner, },
    }
</script>

<style scoped>
    .credits {
        margin: 20px;
    }
    .credits > h4 {
        color: #555555;
    }
</style>
